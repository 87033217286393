import React, { useEffect } from "react";
import { Link } from "gatsby";
import { SEO } from "components";
import { Section } from "components/layout";
import BlockContent from "@sanity/block-content-to-react";
import useMenu from "state/useMenu";
/* Advertise Page
============================================================================= */

const AdvertisePage = ({ data }) => {
	// Config
	const { setAd } = useMenu();

	useEffect(() => {
		setAd();
	}, []);
	return (
		<>
			<SEO title="Advertise" />
			<Section ariaLabel="Advertise" title="Advertise">
				<BlockContent
					blocks={data.sanityAdvertise._rawAdvertiseContent}
					className="block-content prose-xl padding-bottom"
					renderContainerOnSingleChild
				/>
			</Section>
		</>
	);

	// Render
};

/* Page Query
============================================================================= */

export const pageQuery = graphql`
	query AdvertisePageQuery {
		sanityAdvertise {
			_rawAdvertiseContent(resolveReferences: { maxDepth: 10 })
		}
	}
`;

/* Export
============================================================================= */

export default AdvertisePage;
